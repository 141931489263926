import _export from "../internals/export";
import _regexpExec from "../internals/regexp-exec";
var $ = _export;
var exec = _regexpExec;

// `RegExp.prototype.exec` method
// https://tc39.es/ecma262/#sec-regexp.prototype.exec
$({
  target: "RegExp",
  proto: true,
  forced: /./.exec !== exec
}, {
  exec: exec
});
export default {};